import React, { FC } from "react";
import "./index.scss";
import { Button, Logo } from "@/modules/shared";
import { useTranslation } from "react-i18next";

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <Logo />
      <Button
        isButton={false}
        href={t("link-get-funds").toString()}
        target={t("link-get-funds-target").toString()}
      >
        {t("header-button")}
      </Button>
    </div>
  );
};

export { Header };
