const scrollingBottomSection = (currentHeight: number) => {
  if (
    currentHeight >
    (document.querySelector("#networking")! as HTMLElement).offsetTop -
      window.innerHeight
  ) {
    document.querySelector("#networking")?.classList.add("shift");
  }
  if (
    currentHeight >
    (document.querySelector("#networking_img")! as HTMLElement).offsetTop -
      window.innerHeight
  ) {
    document.querySelector("#networking_img")?.classList.add("shift");
  }
  if (
    currentHeight >
    (document.querySelector("#marketing")! as HTMLElement).offsetTop -
      window.innerHeight
  ) {
    document.querySelector("#marketing")?.classList.add("shift");
  }
  if (
    currentHeight >
    (document.querySelector("#marketing_img")! as HTMLElement).offsetTop -
      window.innerHeight
  ) {
    document.querySelector("#marketing_img")?.classList.add("shift");
  }
  if (
    currentHeight >
    (document.querySelector("#product")! as HTMLElement).offsetTop -
      window.innerHeight
  ) {
    document.querySelector("#product")?.classList.add("shift");
  }
  if (
    currentHeight >
    (document.querySelector("#product_img")! as HTMLElement).offsetTop -
      window.innerHeight
  ) {
    document.querySelector("#product_img")?.classList.add("shift");
    document
      .querySelector(".bottom-section")
      ?.setAttribute("style", "margin-top: 0;");
  }
};

export { scrollingBottomSection };
