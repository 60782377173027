import React, { FC, useMemo } from "react";
import "./index.scss";
import { Button, useResize } from "@/modules/shared";
import TwitterLogo from "@/icons/twitter.svg";
import MailLogo from "@/icons/mail.svg";
import { useTranslation } from "react-i18next";

const Footer: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  return (
    <div className="footer">
      <div className="h3 footer__title">{t("footer-title")}</div>

      {!mobileView && (
        <div className="footer__container">
          <Button
            isButton={false}
            href={t("link-apply-for-application").toString()}
            target={t("link-apply-for-application-target").toString()}
          >
            <span className="h4">{t("footer-button-apply")}</span>
          </Button>
          <Button
            isButton={false}
            href={t("link-twitter").toString()}
            target={t("link-twitter-target").toString()}
          >
            <TwitterLogo />
          </Button>
          <Button
            isButton={false}
            href={t("link-email").toString()}
            target={t("link-email-target").toString()}
          >
            <MailLogo />
          </Button>
        </div>
      )}

      {mobileView && (
        <div className="footer__container">
          <Button
            isButton={false}
            href={t("link-apply-for-application").toString()}
            target={t("link-apply-for-application-target").toString()}
          >
            <span className="h4">{t("footer-button-apply")}</span>
          </Button>
        </div>
      )}

      {mobileView && (
        <div className="footer__container-mobile">
          <Button
            isButton={false}
            href={t("link-twitter").toString()}
            target={t("link-twitter-target").toString()}
          >
            <TwitterLogo />
          </Button>
          <Button
            isButton={false}
            href={t("link-email").toString()}
            target={t("link-email-target").toString()}
          >
            <MailLogo />
          </Button>
        </div>
      )}

      <div className="footer__copyright h4">
        © {new Date().getFullYear()}
        {t("footer-copyright")}
      </div>
    </div>
  );
};

export { Footer };
