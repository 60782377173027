import React, { FC, ReactNode } from "react";
import "./index.scss";

type WhitePixelBlockProps = { children: ReactNode };

const WhitePixelBlock: FC<WhitePixelBlockProps> = (props) => {
  return (
    <div className="white-pixel__block">
      <div className="bh1">{props.children}</div>
    </div>
  );
};

export { WhitePixelBlock };
