import React, { FC } from "react";
import "./index.scss";
import logoImg from "@/assets/images/logo/black_alpha.png";
import logoGif from "@/assets/images/gifs/head_btn_slow_x3.gif";
import { useTranslation } from "react-i18next";

const Logo: FC = () => {
  const { t } = useTranslation();

  return (
    <a
      className="logo_header"
      href={t("link-logo").toString()}
      target={t("link-logo-target").toString()}
    >
      <img alt="logo" src={logoImg} />
      <img alt="logo_hover" src={logoGif} />
    </a>
  );
};

export { Logo };
