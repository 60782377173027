import React, { FC, useState } from "react";
import "./index.scss";
import WhiteLogo from "@/icons/white_vector.svg";
import { Button } from "@/modules/shared";
import videoHead from "@/assets/images/gifs/start_video_invert.gif";
import { useTranslation } from "react-i18next";

const disappear = () => {
  document.querySelector(".pre-screen")?.classList.add("disappear-screen");
  document
    .querySelector(".app-wrapper")
    ?.classList.remove("app-wrapper__preload");
};

const PreScreen: FC = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  setTimeout(() => {
    setShow(true);
    setTimeout(() => {
      document.querySelector(".invisible")?.classList.add("appear");
    }, 200);
  }, 1500);

  return (
    <div className="pre-screen">
      {show && (
        <img className="invisible" alt="invert head video" src={videoHead} />
      )}
      <WhiteLogo />
      <Button isButton onClick={disappear}>
        {t("pre-screen-button")}
      </Button>
    </div>
  );
};

export { PreScreen };
