import React, { FC } from "react";
import { Route, Navigate, Routes as RouterRoutes } from "react-router-dom";
import Landing from "@/pages/landing";

const Routes: FC = () => (
  <RouterRoutes>
    <Route path="/" element={<Landing />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </RouterRoutes>
);

export default Routes;
