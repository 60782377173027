/* eslint-disable */
import data from "@/assets/scrollings/scrollings.json";

const t = (str: string): any => {
  // @ts-ignore
  return data[str];
};

const scrollingKickstartSection = (currentHeight: number) => {
  const { innerWidth } = window;
  const checkedHeightS2 =
    innerWidth < 1000
      ? parseInt(t("scrolling-kickstart-tablet"))
      : parseInt(t("scrolling-kickstart-desktop"));
  const checkedHeight =
    innerWidth < 600
      ? parseInt(t("scrolling-kickstart-mobile"))
      : checkedHeightS2;
  const triggerHeight =
    checkedHeight + parseInt(t("scrolling-kickstart-scroll"));
  const checkedHeight2S2 =
    innerWidth < 1000
      ? parseInt(t("scrolling-project-tablet"))
      : parseInt(t("scrolling-project-desktop"));
  const checkedHeight2 =
    innerWidth < 600
      ? parseInt(t("scrolling-project-mobile"))
      : checkedHeight2S2;
  const triggerHeight2 =
    checkedHeight2 + parseInt(t("scrolling-kickstart-scroll"));

  if (currentHeight > checkedHeight && currentHeight <= triggerHeight) {
    const margin = currentHeight - triggerHeight;
    document
      .querySelector("#kickstart")
      ?.setAttribute(
        "style",
        `top: ${
          150 -
          ((parseInt(t("scrolling-kickstart-scroll")) - Math.abs(margin)) /
            parseInt(t("scrolling-kickstart-scroll"))) *
            107
        }%`
      );
  }

  if (currentHeight > checkedHeight2 && currentHeight <= triggerHeight2) {
    const margin = currentHeight - triggerHeight2;
    document
      .querySelector("#project")
      ?.setAttribute(
        "style",
        `top: ${
          150 -
          ((parseInt(t("scrolling-kickstart-scroll")) - Math.abs(margin)) /
            parseInt(t("scrolling-kickstart-scroll"))) *
            105
        }%`
      );
  }
};

export { scrollingKickstartSection };
