/* eslint-disable */
import data from "@/assets/scrollings/scrollings.json";

const t = (str: string): any => {
  // @ts-ignore
  return data[str];
};

const scrollingItemsFromSwiper = (currentHeight: number) => {
  const { innerWidth } = window;
  const checkedHeight2 =
    innerWidth < 1000
      ? parseInt(t("scrolling-items-from-swiper-tablet"))
      : parseInt(t("scrolling-items-from-swiper-desktop"));
  const checkedHeight =
    innerWidth < 600
      ? parseInt(t("scrolling-items-from-swiper-mobile"))
      : checkedHeight2;
  const triggerHeight =
    checkedHeight + parseInt(t("scrolling-items-from-swiper-scroll"));

  if (currentHeight > checkedHeight && currentHeight <= triggerHeight) {
    const margin = currentHeight - triggerHeight;
    document
      .querySelector("#kickstart")
      ?.setAttribute(
        "style",
        `top: ${
          43 -
          ((parseInt(t("scrolling-items-from-swiper-scroll")) -
            Math.abs(margin)) /
            parseInt(t("scrolling-items-from-swiper-scroll"))) *
            93
        }%`
      );
  }

  if (currentHeight > checkedHeight && currentHeight <= triggerHeight) {
    const margin = currentHeight - triggerHeight;
    document
      .querySelector("#project")
      ?.setAttribute(
        "style",
        `top: ${
          45 -
          ((parseInt(t("scrolling-items-from-swiper-scroll")) -
            Math.abs(margin)) /
            parseInt(t("scrolling-items-from-swiper-scroll"))) *
            95
        }%`
      );
  }

  if (currentHeight > checkedHeight && currentHeight <= triggerHeight) {
    const margin = currentHeight - triggerHeight;
    document
      .querySelector("#logo_gif")
      ?.setAttribute(
        "style",
        `top: ${
          45 -
          ((parseInt(t("scrolling-items-from-swiper-scroll")) -
            Math.abs(margin)) /
            parseInt(t("scrolling-items-from-swiper-scroll"))) *
            95
        }%`
      );
  }
};

export { scrollingItemsFromSwiper };
