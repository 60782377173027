import React, { FC, useMemo } from "react";
import "./index.scss";
import { SwiperSlider, useResize } from "@/modules/shared";
import { useTranslation } from "react-i18next";

const Swiper: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  return (
    <div className="swiper-molecule">
      {!mobileView && (
        <div className="swiper-molecule__title h1">
          {t("swiper-section-mobile-title")}
          <div className="swiper-molecule__subtitle">
            {t("swiper-section-subtitle")}
          </div>
        </div>
      )}
      {mobileView && (
        <div className="swiper-molecule__title h1">
          {t("swiper-section-mobile-title")}
          <div className="swiper-molecule__subtitle">
            {t("swiper-section-subtitle")}
          </div>
        </div>
      )}

      <SwiperSlider />
    </div>
  );
};

export { Swiper };
