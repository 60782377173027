import React, { FC, useMemo } from "react";
import "./index.scss";
import {
  BlackPixelBlock,
  BlankWindow,
  Button,
  VideoHead,
  WhitePixelBlock,
  KickstartElement,
  useResize,
} from "@/modules/shared";
import Horadrim from "@/icons/HORADRIM.svg";
import Capital from "@/icons/CAPITAL.svg";
import LogoAnimatedGif from "@/assets/images/motion_elements/animation_1.gif";
import { useTranslation } from "react-i18next";

const HeadSection: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  return (
    <div className="head_section">
      <VideoHead />
      <div className="head_section__text">
        <Horadrim />
        <span>
          <Capital />
          {!mobileView && (
            <Button
              isButton={false}
              href={t("link-wanna-funds").toString()}
              target={t("link-wanna-funds-target").toString()}
            >
              {t("head-section-button-1st-word")}
              <br />
              {t("head-section-button-2nd-word")}
            </Button>
          )}
        </span>
        {mobileView && (
          <div className="head_section__button-mobile">
            <Button
              isButton={false}
              href={t("link-wanna-funds").toString()}
              target={t("link-wanna-funds-target").toString()}
            >
              {t("head-section-button-1st-word")}{" "}
              {t("head-section-button-2nd-word")}
            </Button>
          </div>
        )}
      </div>
      <img
        className="logo_gif-central"
        id="logo_gif"
        src={LogoAnimatedGif}
        alt="animated logo"
      />
      <BlankWindow style={{ marginTop: "400px" }}>
        <div className="middle-block">
          <WhitePixelBlock>
            {t("head-section-first-block-sent-1")} {!mobileView && <br />}
            {t("head-section-first-block-sent-2")} {!mobileView && <br />}
            {t("head-section-first-block-sent-3")}
          </WhitePixelBlock>
          <BlackPixelBlock>
            {t("head-section-second-block-sent-1")} {!mobileView && <br />}
            {t("head-section-second-block-sent-2")} {!mobileView && <br />}
            {t("head-section-second-block-sent-3")} {!mobileView && <br />}
            {t("head-section-second-block-sent-4")} {!mobileView && <br />}
            {t("head-section-second-block-sent-5")}
          </BlackPixelBlock>
          <WhitePixelBlock>
            {t("head-section-third-block-sent-1")} {!mobileView && <br />}
            {t("head-section-third-block-sent-2")} {!mobileView && <br />}
            {t("head-section-third-block-sent-3")}
          </WhitePixelBlock>
        </div>
      </BlankWindow>
      <div className="section__kickstart">
        <KickstartElement />
        <div id="project" className="h1">
          {t("head-section-project-p1")} <br />
          {mobileView && <br />}
          {t("head-section-project-p2")}
        </div>
      </div>
    </div>
  );
};

export { HeadSection };
