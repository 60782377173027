import React, { CSSProperties, FC, ReactNode } from "react";
import "./index.scss";

type BlankWindowProps = { style: CSSProperties; children: ReactNode };

const BlankWindow: FC<BlankWindowProps> = (props) => {
  return (
    <div className="blank-window" style={props.style}>
      {props.children}
    </div>
  );
};

export { BlankWindow };
