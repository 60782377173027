/* eslint-disable */
import data from "@/assets/scrollings/scrollings.json";

const t = (str: string): any => {
  // @ts-ignore
  return data[str];
};

const scrollingLogo = (currentHeight: number) => {
  const { innerWidth } = window;
  const triggerLimit =
    innerWidth < 600
      ? parseInt(t("scrolling-logo-trigger-limit-mobile"))
      : parseInt(t("scrolling-logo-trigger-limit-desktop"));
  const limitOffset = parseInt(t("scrolling-logo-offset-limit"));
  let limitOffsetLogo =
    innerWidth < 1000
      ? parseInt(t("scrolling-logo-offset-limit-percent-tablet"))
      : parseInt(t("scrolling-logo-offset-limit-percent-desktop"));
  limitOffsetLogo =
    innerWidth < 600
      ? parseInt(t("scrolling-logo-offset-limit-percent-mobile"))
      : limitOffsetLogo;
  let offsetPercent =
    innerWidth < 1000
      ? parseInt(t("scrolling-logo-limit-percent-tablet"))
      : parseInt(t("scrolling-logo-limit-percent-desktop"));
  offsetPercent =
    innerWidth < 600
      ? parseInt(t("scrolling-logo-limit-percent-mobile"))
      : offsetPercent;

  if (
    currentHeight > triggerLimit &&
    currentHeight < triggerLimit + parseInt(t("scrolling-logo-scroll"))
  ) {
    const margin = currentHeight - triggerLimit - limitOffset;
    document
      .querySelector("#logo_gif")
      ?.setAttribute(
        "style",
        `top: ${
          limitOffsetLogo -
          ((limitOffset - Math.abs(margin)) / limitOffset) * offsetPercent
        }%`
      );
  }
};

export { scrollingLogo };
