import React, { FC, ReactNode } from "react";
import "./index.scss";

type TextBlockProps = { children: ReactNode; header: string; id?: string };

const TextBlock: FC<TextBlockProps> = (props) => {
  return (
    <div id={props.id} className="text-block">
      <h3 className="text-block__header h3">{props.header}</h3>
      <div className="text-block__text">{props.children}</div>
    </div>
  );
};

export { TextBlock };
