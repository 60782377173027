import React, { FC, ReactNode } from "react";
import "./index.scss";

type BlackPixelBlockProps = { children: ReactNode };

const BlackPixelBlock: FC<BlackPixelBlockProps> = (props) => {
  return (
    <div className="black-pixel__block">
      <div className="bh1">{props.children}</div>
    </div>
  );
};

export { BlackPixelBlock };
