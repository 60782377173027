/* eslint-disable */
import data from "@/assets/scrollings/scrollings.json";

const t = (str: string): any => {
  // @ts-ignore
  return data[str];
};

const scrollingTopTitle = (currentHeight: number) => {
  const { innerWidth } = window;
  const checkPreH =
    innerWidth < 1300
      ? parseInt(t("scrolling-top-title-desktop"))
      : parseInt(t("scrolling-top-title-big-desktop"));
  const checkPreH2 =
    innerWidth < 1000 ? parseInt(t("scrolling-top-title-tablet")) : checkPreH;
  const checkedHeight =
    innerWidth < 600 ? parseInt(t("scrolling-top-title-mobile")) : checkPreH2;
  const triggerPreH =
    innerWidth < 1300
      ? parseInt(t("scrolling-top-title-trigger-desktop"))
      : parseInt(t("scrolling-top-title-trigger-big-desktop"));
  const triggerPreH2 =
    innerWidth < 1300
      ? parseInt(t("scrolling-top-title-trigger-tablet"))
      : triggerPreH;
  const triggerHeight =
    innerWidth < 600
      ? parseInt(t("scrolling-top-title-trigger-mobile"))
      : triggerPreH2;
  const triggerLimit = parseInt(t("scrolling-top-title-trigger-limit-scroll"));
  const limitOffset = parseInt(
    t("scrolling-top-title-trigger-limit-offset-scroll")
  );
  let offsetPercent =
    innerWidth < 1000
      ? parseInt(t("scrolling-top-title-trigger-offset-percent-tablet"))
      : parseInt(t("scrolling-top-title-trigger-offset-desktop"));
  offsetPercent =
    innerWidth < 600
      ? parseInt(t("scrolling-top-title-trigger-offset-percent-mobile"))
      : offsetPercent;

  if (currentHeight > triggerHeight && currentHeight < triggerLimit) {
    const margin = Math.min(currentHeight - checkedHeight - triggerHeight, 0);
    document
      .querySelector(".head_section__text")
      ?.setAttribute(
        "style",
        `transform: translateY(${checkedHeight + margin}px)`
      );
    document
      .querySelector("#logo_gif")
      ?.setAttribute(
        "style",
        `top: ${
          limitOffset -
          ((checkedHeight - Math.abs(margin)) / checkedHeight) * offsetPercent
        }%`
      );
  } else if (currentHeight < triggerLimit) {
    document
      .querySelector(".head_section__text")
      ?.setAttribute("transform", `transform: translateY(${checkedHeight})`);
  }
};

export { scrollingTopTitle };
