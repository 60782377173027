import React, { FC, MouseEventHandler, ReactNode } from "react";
import "./index.scss";
import videoNoise from "@/assets/images/gifs/head_btn.gif";

type ButtonProps = {
  children: ReactNode;
  onClick?: MouseEventHandler;
  isButton: boolean;
  href?: string;
  target?: string;
};

const Button: FC<ButtonProps> = (props) => {
  if (props.isButton) {
    return (
      <button
        type="button"
        onClick={props.onClick}
        className="button-wrapper h3"
      >
        <img src={videoNoise} alt="btn noise" />
        <span> {props.children} </span>
      </button>
    );
  }

  return (
    <a href={props.href} target={props.target} className="button-wrapper h3">
      <img src={videoNoise} alt="btn noise" />
      <span> {props.children} </span>
    </a>
  );
};

export { Button };
