import React, { FC, useMemo } from "react";
import { Button, useResize } from "@/modules/shared";
import Horadrim from "@/icons/HORADRIM.svg";
import Capital from "@/icons/CAPITAL.svg";
import { useTranslation } from "react-i18next";
import "./index.scss";

const HeadText: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  return (
    <div className="head-text-wrapper">
      <div className="head-text">
        <Horadrim />
        <span>
          <Capital />
          {!mobileView && (
            <Button
              isButton={false}
              href={t("link-wanna-funds").toString()}
              target={t("link-wanna-funds-target").toString()}
            >
              {t("head-section-button-1st-word")}
              <br />
              {t("head-section-button-2nd-word")}
            </Button>
          )}
        </span>
        {mobileView && (
          <div className="head-text-button-mobile">
            <Button
              isButton={false}
              href={t("link-wanna-funds").toString()}
              target={t("link-wanna-funds-target").toString()}
            >
              {t("head-section-button-1st-word")}{" "}
              {t("head-section-button-2nd-word")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export { HeadText };