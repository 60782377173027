import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import resources from "@/assets/text-content/i18n.json";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import Routes from "./routes";
import ErrorFallback from "./errorFallback";

import "./index.scss";

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

const App: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="app-wrapper app-wrapper__preload">
      <Helmet>
        <meta name="theme-color" content={t("meta-theme-color").toString()} />
        <meta name="description" content={t("meta-description").toString()} />
        <meta name="keywords" content={t("meta-keywords").toString()} />
        <meta property="og:title" content={t("meta-og-title").toString()} />
        <meta property="og:type" content={t("meta-og-type").toString()} />
        <meta
          property="og:description"
          content={t("meta-og-description").toString()}
        />
        <meta property="og:image" content={t("meta-og-image").toString()} />
        <meta property="og:url" content={t("meta-og-url").toString()} />
        <meta
          name="twitter:image"
          content={t("meta-twitter-image").toString()}
        />
        <meta name="twitter:card" content={t("meta-twitter-card").toString()} />
        <meta name="twitter:site" content={t("meta-twitter-site").toString()} />
        <meta
          name="twitter:title"
          content={t("meta-twitter-title").toString()}
        />
        <meta
          name="twitter:description"
          content={t("meta-twitter-description").toString()}
        />
        <link
          rel="apple-touch-icon"
          href={t("meta-apple-touch-icon").toString()}
        />
        <link rel="manifest" href={t("meta-manifest").toString()} />
        <title>{t("meta-title")}</title>
      </Helmet>

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
};

export default App;
