import React, { FC, useMemo } from "react";
import "./index.scss";
import { TextBlock, useResize } from "@/modules/shared";
import networkingImg from "@/assets/images/illustartions/comm.png";
import marketingImg from "@/assets/images/illustartions/net.png";
import productImg from "@/assets/images/illustartions/dev.png";
import { useTranslation } from "react-i18next";

const BottomSection: FC = () => {
  const { t } = useTranslation();

  const { windowWidth } = useResize();

  const bigLaptop = useMemo(() => {
    return windowWidth > 1300;
  }, [windowWidth]);

  return (
    <div className="bottom-section">
      <div className="bottom-section__block">
        <TextBlock id="networking" header="Networking & HR">
          {t("networking-sent-1")}
          <span>
            &nbsp;<span>{t("networking-tagged")}</span>
          </span>{" "}
          {t("networking-sent-2")}
          {bigLaptop && <br />}
          {t("networking-sent-3")} {bigLaptop && <br />}
          {t("networking-sent-4")}
        </TextBlock>
        <img
          alt="networking_img"
          id="networking_img"
          src={networkingImg}
          className="networking_img"
        />
      </div>
      <div className="bottom-section__block">
        <img
          alt="marketing_img"
          id="marketing_img"
          src={marketingImg}
          className="marketing_img"
        />
        <TextBlock id="marketing" header="Marketing & Community">
          {t("marketing-sent-1")} {bigLaptop && <br />}{" "}
          {t("marketing-sent-2-part-1")}{" "}
          <span>
            <span>{t("marketing-tagged")}</span>
          </span>{" "}
          {t("marketing-sent-2-part-2")}
          {bigLaptop && <br />} {t("marketing-sent-3")}
          {bigLaptop && <br />} {t("marketing-sent-4")}
        </TextBlock>
      </div>
      <div className="bottom-section__block">
        <TextBlock id="product" header="Product development">
          {t("product-sent-1")}{" "}
          <span>
            <span>{t("product-tagged")}</span>
          </span>{" "}
          {bigLaptop && <br />}
          {t("product-sent-2")} {bigLaptop && <br />}
          {t("product-sent-3")}
        </TextBlock>
        <img
          alt="product_img"
          id="product_img"
          src={productImg}
          className="product_img"
        />
      </div>
    </div>
  );
};

export { BottomSection };
