/* eslint-disable */
import data from "@/assets/scrollings/scrollings.json";

const t = (str: string): any => {
  // @ts-ignore
  return data[str];
};

const scrollingFix = (currentHeight: number) => {
  const { innerWidth } = window;
  // const checkedHeight = innerWidth < 1300 ? 275 : 345;
  // const triggerHeight = innerWidth < 1300 ? 200 : 355;
  const triggerPreH =
    innerWidth < 1300
      ? parseInt(t("scrolling-top-title-trigger-desktop"))
      : parseInt(t("scrolling-top-title-trigger-big-desktop"));
  const triggerPreH2 =
    innerWidth < 1300
      ? parseInt(t("scrolling-top-title-trigger-tablet"))
      : triggerPreH;
  const triggerHeightTopSection =
    innerWidth < 600
      ? parseInt(t("scrolling-top-title-trigger-mobile"))
      : triggerPreH2;
  const triggerLimitTopSection = parseInt(
    t("scrolling-top-title-trigger-limit-scroll")
  );
  // const limitOffset = innerWidth < 1300 ? 150 : 150;

  const checkPreH =
    innerWidth < 1300
      ? parseInt(t("scrolling-top-title-desktop"))
      : parseInt(t("scrolling-top-title-big-desktop"));
  const checkPreH2 =
    innerWidth < 1000 ? parseInt(t("scrolling-top-title-tablet")) : checkPreH;
  const checkedHeight22 =
    innerWidth < 600 ? parseInt(t("scrolling-top-title-mobile")) : checkPreH2;

  if (currentHeight < triggerHeightTopSection) {
    document
      .querySelector(".head_section__text")
      ?.setAttribute("style", `transform: translateY(0)`);
  } else if (currentHeight > triggerLimitTopSection) {
    document
      .querySelector(".head_section__text")
      ?.setAttribute("style", `transform: translateY(${checkedHeight22}px)`);
  }

  const checkedHeightForLast2 =
    innerWidth < 1000
      ? parseInt(t("scrolling-items-from-swiper-tablet"))
      : parseInt(t("scrolling-items-from-swiper-desktop"));
  const checkedHeightForLast =
    innerWidth < 600
      ? parseInt(t("scrolling-items-from-swiper-mobile"))
      : checkedHeightForLast2;
  const triggerHeightForLast =
    checkedHeightForLast + parseInt(t("scrolling-items-from-swiper-scroll"));

  if (currentHeight > triggerHeightForLast) {
    document.querySelector("#logo_gif")?.setAttribute("style", `top: -50%`);
  } else if (currentHeight > triggerLimitTopSection + 437) {
    document.querySelector("#logo_gif")?.setAttribute("style", `top: 40%`);
  } else if (currentHeight < triggerLimitTopSection) {
    document.querySelector("#logo_gif")?.setAttribute("style", `top: 150%`);
  }

  const checkedHeightS2 =
    innerWidth < 1000
      ? parseInt(t("scrolling-kickstart-tablet"))
      : parseInt(t("scrolling-kickstart-desktop"));
  const checkedHeight =
    innerWidth < 600
      ? parseInt(t("scrolling-kickstart-mobile"))
      : checkedHeightS2;
  const triggerHeight =
    checkedHeight + parseInt(t("scrolling-kickstart-scroll"));
  const checkedHeight2S2 =
    innerWidth < 1000
      ? parseInt(t("scrolling-project-tablet"))
      : parseInt(t("scrolling-project-desktop"));
  const checkedHeight2 =
    innerWidth < 600
      ? parseInt(t("scrolling-project-mobile"))
      : checkedHeight2S2;
  const triggerHeight2 =
    checkedHeight2 + parseInt(t("scrolling-kickstart-scroll"));

  if (currentHeight > triggerHeightForLast) {
    document.querySelector("#kickstart")?.setAttribute("style", `top: -50%`);
  } else if (currentHeight > triggerHeight) {
    document.querySelector("#kickstart")?.setAttribute("style", `top: 43%`);
  } else if (currentHeight < checkedHeight) {
    document.querySelector("#kickstart")?.setAttribute("style", `top: 150%`);
  }

  if (currentHeight > triggerHeightForLast) {
    document.querySelector("#project")?.setAttribute("style", `top: -50%`);
  } else if (currentHeight > triggerHeight2) {
    document.querySelector("#project")?.setAttribute("style", `top: 45%`);
  } else if (currentHeight < checkedHeight2) {
    document.querySelector("#project")?.setAttribute("style", `top: 150%`);
  }
};

export { scrollingFix };
