import React, { FC } from "react";
import "./index.scss";
import videoHead from "@/assets/images/gifs/Head_video.gif";

const VideoHead: FC = () => {
  return (
    <div className="video-head">
      <img width="100%" src={videoHead} alt="head video" />
    </div>
  );
};

export { VideoHead };
