import React, { FC } from "react";
import {
  BottomSection,
  Footer,
  Header,
  HeadSection,
  PreScreen,
  Swiper,
  HeadText,
} from "@/modules/shared";
import "./index.scss";

const Hero: FC = () => {
  return (
    <div className="hero-wrapper">
      <PreScreen />
      <div className="hero-wrapper__small">
        <Header />
        <HeadSection />
        <HeadText />
      </div>
      <Swiper />
      <BottomSection />
      <Footer />
    </div>
  );
};

export { Hero };
