import React, { FC } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const KickstartElement: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="kickstart" className="kickstart">
      {t("kickstart")}
    </div>
  );
};

export { KickstartElement };
