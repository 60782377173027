import { setAutoplayConfigsFunc } from "@/modules/shared/atoms/swiper-slider";
import { scrollingTopTitle } from "./scrolling-top-title";
import { scrollingLogo } from "./scrolling-logo";
import { scrollingKickstartSection } from "./scrolling-kickstart-section";
import { scrollingItemsFromSwiper } from "./scrolling-items-from-swiper";
import { scrollingFix } from "./scrolling-fix";
import { scrollingBottomSection } from "./scrolling-bottom-section";

const scrollingTool = () => {
  // window.addEventListener(
  //   "mousewheel",
  //   (e) => {
  //     e.preventDefault();
  //     window.requestAnimationFrame(() => {
  //       // @ts-ignore
  //       if (e?.deltaY > 0) {
  //         window.scrollTo({
  //           top: window.scrollY + 100,
  //           left: 0,
  //           behavior: "auto",
  //         });
  //       } else {
  //         window.scrollTo({
  //           top: window.scrollY - 100,
  //           left: 0,
  //           behavior: "auto",
  //         });
  //       }
  //     });
  //   },
  //   { passive: false }
  // );

  let lastKnownScrollPosition = 0;

  window.onscroll = function () {
    lastKnownScrollPosition = window.scrollY;

    scrollingFix(lastKnownScrollPosition);
    scrollingTopTitle(lastKnownScrollPosition);
    scrollingLogo(lastKnownScrollPosition);
    scrollingKickstartSection(lastKnownScrollPosition);
    scrollingItemsFromSwiper(lastKnownScrollPosition);
    scrollingBottomSection(lastKnownScrollPosition);

    if (
      (document.querySelector(".swiper-slider__container")! as HTMLElement)
        .offsetTop -
        500 <
      lastKnownScrollPosition
    ) {
      console.log("DONE");
      // @ts-ignore
      setAutoplayConfigsFunc[0](true);
    }
  };
};

export { scrollingTool };
